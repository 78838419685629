import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const MitmachenPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ort: file(relativePath: { eq: "wahl2020/kandidatenreihe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid mobile-header-bg'
        style={{ backgroundPosition: 'top center' }}
        fluid={data.ort.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light mobile-header'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Mitmachen
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <p>
          Die Kraft zu notwendigen Veränderungen hat eine Partei umso eher, je
          stärker sie in ihrer Heimat verankert ist. Wir sind Bayerns
          Volkspartei. Die große Mitgliederzahl der CSU war und ist das
          Fundament unserer Politik. Sie schafft uns den nötigen Rückhalt in der
          Bevölkerung. Die CSU-Verbände mit ihrer breiten Verwurzelung an der
          Basis ermöglichen Politik aus einem Guss und auf allen politischen
          Ebenen: in den Gemeinden, Landkreisen und Bezirken ebenso wie in den
          Parlamenten in München, Berlin und Brüssel.
        </p>

        <p>
          Wir sind die Mitmachpartei – auch gerne mit Ihnen. Wenn Sie Interesse
          haben, sich politisch zu engagieren und Ihre Zukunfts mitzugestalten,
          freuen wir uns Sie als Mitglied begrüßen zu dürfen.
        </p>

        <a
          href='https://www.csu.de/mitgliedsantrag/'
          target='_blank'
          className='btn btn-primary'>
          Den Mitgliedsantrag finden sie hier
        </a>
      </div>
    </Layout>
  )
}

export default MitmachenPage
